<template>
  <v-container
    fluid
    py-0
    class="blue"
    style="position: fixed; overflow: scroll; height: 100vh"
  >
    <v-row align="center" justify="center" style="height: 100vh">
      <div class="pa-5" style="width: 600px">
        <div v-if="uploadStatus == 0">
          <v-card class="pa-8 ma-4" max-width="600">
            <v-alert color="grey darken-3 fs-14 fw-bold" dark>
              {{ userOrgInfo.organizationName
              }}<span class="fs-12 ml-2">からの請求依頼</span>
            </v-alert>
            <div class="mb-5">
              {{ userOrgInfo.userName }} さんから請求依頼が届いています。<br />
              タイトル・コメントを入力の上、請求書をアップロードください。
            </div>
            <v-text-field
              v-model="uploadForm.name"
              outlined
              filled
              dense
              class="mb-3"
              hide-details
              label="氏名"
            />
            <v-text-field
              v-model="uploadForm.mail"
              outlined
              filled
              dense
              class="mb-3"
              hide-details
              label="メールアドレス"
            />
            <v-text-field
              :value="phonenumberWithhyphen"
              outlined
              filled
              dense
              class="mb-3"
              hide-details
              label="電話番号"
              @input="uploadForm.phoneNumber = $event.replace(/-/g, '')"
            />
            <v-text-field
              v-model="uploadForm.title"
              outlined
              filled
              dense
              class="mb-3"
              hide-details
              label="請求タイトル"
            />
            <v-textarea
              v-model="uploadForm.comment"
              outlined
              filled
              class="mb-3"
              hide-details
              label="請求先へのコメント"
            />
            <v-form style="width: 100%">
              <dropzone
                id="myVueDropzone"
                refs="dropzone"
                class="grey lighten-3"
                :class="$style.myTempUploadVueDropzone"
                :options="dropzoneOptions"
                @vdropzone-files-added="onAddMultiple"
                @vdropzone-file-added="removePreview"
                @vdropzone-sending="sendingFile"
                @vdropzone-success="success"
                @vdropzone-sending-multiple="sendingMultiple"
                @vdropzone-processing="processingFile"
                @vdropzone-queue-complete="qcomplete"
                @vdropzone-complete="complete"
                @vdropzone-upload-progress="progress"
                @vdropzone-mounted="mounted"
              >
                <input type="hidden" name="token" value="xxx" />
              </dropzone>
            </v-form>
            <v-progress-linear
              v-show="totalItems > 0"
              v-model="buffer"
              :buffer-value="bufferValue"
            />
            <div v-show="totalItems > 0" class="text-center">
              <div class="mt-1">{{ currItems }} / {{ totalItems }}</div>
            </div>
          </v-card>
        </div>
        <v-container v-else-if="uploadStatus == 1">
          <v-row class="justify-center">
            <div class="fw-bold white--text">アップロード中です</div>
          </v-row>
          <v-row class="justify-center my-5">
            <v-progress-circular indeterminate color="white" />
          </v-row>
        </v-container>
        <v-row v-else-if="uploadStatus == 2" class="justify-center">
          <div class="fw-bold white--text">アップロードが完了しました。</div>
        </v-row>
        <div class="text-center white--text mt-3">
          このページは
          <v-btn href="https://sweeep.ai" target="_blank" small> sweeep </v-btn>
          で運営されています。
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import Dropzone from 'vue2-dropzone'
import Vue from 'vue'
import { successNotify, errorNotify } from 'Helpers/helpers'
import Axios from 'axios'
import { AsYouType } from 'libphonenumber-js'

export default {
  components: {
    Dropzone,
  },
  data() {
    return {
      attachmentPage: 5,
      multiple: false,
      dropzoneOptions: {},
      uploading: false,
      successCount: 0,
      currItems: 0,
      totalItems: 0,
      bufferValue: 100,
      uploadStatus: 0, // 0:before upload, 1:uploading, 2: upload success
      uploadForm: {
        name: '',
        mail: '',
        phoneNumber: '',
        title: '',
        comment: '',
      },
      userOrgInfo: {
        userName: '',
        organizationName: '',
      },
    }
  },
  computed: {
    buffer: function () {
      return (this.currItems / this.totalItems) * 100
    },
    phonenumberWithhyphen() {
      return new AsYouType('JP').input(this.uploadForm.phoneNumber)
    },
  },
  watch: {
    multiple: function (val) {
      localStorage.setItem('multiple_upload', val)
    },
    currItems: function () {
      this.enableDropzone()
    },
  },
  created() {
    this.dropzoneOptions = {
      url: this.$store.getters.apiTempUploadFunction,
      thumbnailWidth: 150,
      uploadMultiple: true,
      autoProcessQueue: false,
      maxFilesize: 2,
      method: 'post',
      params: {},
      dictDefaultMessage:
        '<i class="feather-download" style="font-size:20px;color:#000"></i><br><br>ファイルをドラッグ＆ドロップするか<br>クリックして請求書をアップロードください。',
    }
  },
  mounted() {
    try {
      this.initUploadType()
      this.getTempUploadInfo()
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    initUploadType() {
      try {
        var multiple = localStorage.getItem('multiple_upload')
        if (multiple == 'true') {
          this.multiple = true
        } else {
          this.multiple = false
        }
      } catch (e) {
        console.log(e)
      }
    },
    getTempUploadInfo() {
      let url = this.$store.getters.apiTempUploadInfoFunction
      let data = {
        upload_url: location.href,
      }
      let headers = {
        Authorization: this.$store.getters.getAuthToken,
      }

      Axios.get(url, { params: data, headers: headers })
        .then((response) => {
          if (response.data.status == 'success') {
            this.userOrgInfo.userName = response.data.user_name
            this.userOrgInfo.organizationName = response.data.organization_name
          }
        })
        .catch(function (error) {
          errorNotify(error.message)
        })
    },
    uploadFile(file) {
      try {
        var t = this
        let result = t.formValidate()
        if (!result.isValid) {
          errorNotify(result.message)
          this.totalItems = 0
          this.uploading = false
          Vue.nextTick(() => {
            this.$refs.dropzone.removeAllFiles()
          })
          return
        }

        t.uploadStatus = 1
        var reader = new FileReader()
        var status = false

        reader.onload = function (e) {
          status = true

          $.ajax({
            url: t.dropzoneOptions.url,
            type: 'POST',
            dataType: 'json',
            data: {
              title: 'default',
              image: e.target.result,
              upload_url: location.href,
              upload_source: file.name,
              multiple: t.multiple,
              attachment_page: t.attachmentPage,
              upload_title: t.uploadForm.title,
              comment: t.uploadForm.comment,
              sender_user_name: t.uploadForm.name,
              sender_mail_address: t.uploadForm.mail,
              sender_phone_number: t.uploadForm.phoneNumber,
            },
            error: function () {
              errorNotify('Error on uploading ' + file.name)
              t.uploadStatus = 0
            },
            success: function (response) {
              if (response.error) {
                errorNotify(response.message)
                t.uploadStatus = 0
              } else {
                t.currItems += 1
                t.uploadStatus = 2
                successNotify('成功')
              }
              t.clearUploadForm()
            },
          })
        }
        reader.onloadend = function () {
          if (!status) {
            setTimeout(function () {
              t.totalItems += -1
              errorNotify('Cannot upload folder')
            }, 500)
          }
        }
        reader.readAsDataURL(file)
      } catch (error) {
        console.log(error)
      }
    },
    formValidate() {
      let result = {}
      let messages = []
      if (!this.uploadForm.name) {
        result.isValid = false
        messages.push('氏名が未入力です')
      }
      if (!this.uploadForm.mail) {
        result.isValid = false
        messages.push('メールアドレスが未入力です')
      } else if (
        !/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
          this.uploadForm.mail
        )
      ) {
        messages.push('メールアドレスを正しい形式で入力ください')
      }
      if (!this.uploadForm.phoneNumber) {
        result.isValid = false
        messages.push('電話番号が未入力です')
      } else if (
        !/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/.test(
          this.uploadForm.phoneNumber
        )
      ) {
        messages.push('電話番号を正しい形式で入力ください')
      }
      if (!this.uploadForm.title) {
        messages.push('タイトルが未入力です')
      }
      if (!this.uploadForm.comment) {
        messages.push('コメントが未入力です')
      }

      if (messages.length > 0) {
        result.isValid = false
        result.message = messages.join('\n')
      } else {
        result.isValid = true
        result.message = null
      }
      return result
    },
    clearUploadForm() {
      this.uploadForm = {
        title: '',
        comment: '',
      }
    },
    onAddMultiple(files) {
      try {
        if (!this.uploading) {
          if (files.length >= 2) {
            errorNotify(this.$t('message.inbox_multiple_upload_message'))
            Vue.nextTick(() => {
              this.$refs.dropzone.removeAllFiles()
            })
            return
          }

          this.uploading = true
          this.currItems = 0
          this.totalItems = files.length

          this.removePreview()
          this.processingFile()

          this.uploadFile(files[0])

          let t = this
          let fileIndex = 0
          let uploadTimer = setInterval(() => {
            if (t.currItems === fileIndex + 1) {
              fileIndex += 1
              t.uploadFile(files[fileIndex])
            }

            if (fileIndex >= files.length - 1) {
              clearInterval(uploadTimer)
            }
          }, 1000)
        }
      } catch (error) {
        console.log(error)
      }
    },
    qcomplete(file, xhr, formData) {},
    complete(file) {
      $('.dz-hidden-input').prop('disabled', true)
    },
    removePreview() {
      try {
        $('.dropzone').removeClass('dz-started')
        $('.dz-preview').remove()
      } catch (e) {
        console.log(e)
      }
    },
    processingFile() {
      $('.dz-hidden-input').prop('disabled', true)
    },
    enableDropzone() {
      try {
        if (this.currItems === this.totalItems) {
          this.uploading = false
          $('.dz-hidden-input').prop('disabled', false)
        }
      } catch (error) {
        console.log(error)
      }
    },
    sendingFile(file, response, formData) {},
    sendingMultiple(files, xhr, formData) {},
    success(file, response) {},
    progress(file, progress, bytesSent) {},
    mounted() {
      var x = document.getElementsByClassName('dz-hidden-input')
      $('.dz-hidden-input').attr('id', 'input-file-upload')
    },
  },
}
</script>
<style module>
.myTempUploadVueDropzone {
  height: 220px;
  margin-bottom: 10px;
}
</style>
